import request from '@/utils/request'
// 提交idfa 和通讯录
function addressBook () {
  if (isUseLatestLogic()) {
    const contantList = localStorage.getItem('contact')
    const idfa = localStorage.getItem('idfa') || ''
    if (contantList || idfa) {
      const contant = contantList || JSON.stringify([])
      request.post('addressBook', { contantList: contant, idfa: idfa.replace(/"/g, '') })
    }
  }
}

// 判断是否使用最新的相机逻辑
export function isUseLatestLogic () {
  const apitype = sessionStorage.getItem('apitype')
  if (apitype === '3' || apitype === '4' || apitype === '5' || apitype === '6' || apitype === '7') {
    return true
  }
  return false
}

export default addressBook
